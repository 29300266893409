var scrollAnimatron = function(elements, parent) {
	parent = parent || false;
	var checkElements = [];
	
	var forEach = function(array, callback) {
		for (var i = 0; i < array.length; i++) {
			callback(array[i], i);
		}
	};
	
	function addClass(el, className) {
		if (el.classList) el.classList.add(className);
		else el.className += ' ' + className;
	}
	
	function removeClass(el, className) {
		if (el.classList) el.classList.remove(className);
		else el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
	}
	
	function check(el) {
		if (!el.done) {
			var offsett = el.offsetMobile ? el.offsetMobile : el.offset;
			var elTop = el.el.getBoundingClientRect().top,
				isOffset = (elTop + offsett) < window.innerHeight;
			if (isOffset) {
				el.done = true;
				removeClass(el.el, el.hidderClass);
				addClass(el.el, el.classAdd);
				if (el.callback) el.callback(el.el);
			}
		}
	}
	
	this.update = function() {
		checkElements = [];
		init();
	};
	
	this.destroy = function() {
		checkElements = [];
	};
	
	this.scroll = function() {
		forEach(checkElements, check);
	};
	
	function init() {
		forEach(elements, function(el) {
			var	offset = el.offset || 0,
				classAdd = el.classAdd || 'is-animated',
				offsetMobile = el.offsetMobile || false,
				hidderClass = el.hidderClass || 'is-hidd',
				callback = el.callback || false,
				nodes =
					parent ? Array.prototype.slice.call(parent.querySelectorAll(el.el))
						: Array.prototype.slice.call(document.querySelectorAll(el.el));
			
			forEach(nodes, function(el) {
				var params = {
					el: el,
					offset: offset,
					classAdd: classAdd,
					offsetMobile: offsetMobile,
					hidderClass: hidderClass,
					callback: callback,
					done: false
				};
				
				addClass(el, hidderClass);
				check(params);
				
				checkElements.push(params)
			})
		})
	}
	
	init();
};

export default scrollAnimatron;