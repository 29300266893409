var forEach = function(array, callback) {
    for (var i = 0; i < array.length; i++) {
        callback(array[i], i);
    };
};

var videoResize = function(selector){
    var videoContainers = document.querySelectorAll(selector);

    function updateSize(videoCont) {
        var containerWidth = videoCont.clientWidth,
            containerHeight = videoCont.clientHeight,
            videoTag = videoCont.querySelector('video'),
            videoHeight = videoTag.videoHeight,
            videoWidth = videoTag.videoWidth,
            scaleX = containerWidth / videoWidth,
            scaleY = containerHeight / videoHeight,
            scale = Math.max(scaleX, scaleY),
            w = Math.ceil(videoWidth * scale),
            h = Math.ceil(videoHeight * scale),
            x = 0,
            y = 0;

        if (w > containerWidth) x = -(w - containerWidth) * 0.5;
        if (h > containerHeight) y = -(h - containerHeight) * 0.5;
        videoTag.style.width = w + 'px';
        videoTag.style.height = h + 'px';
        videoTag.style.top = y + 'px';
        videoTag.style.left = x + 'px';
    }

    if (videoContainers.length > 0) {
        forEach(videoContainers, function(videoContainer) {
            var videoTag = videoContainer.querySelector('video');
            updateSize(videoContainer);
            videoTag.addEventListener('loadeddata', function() {
                updateSize(videoContainer);
            });
            videoTag.addEventListener('loadedmetadata', function() {
                updateSize(videoContainer);
            });
        });
    };

    this.resize = function() {
        if (videoContainers.length > 0) {
            forEach(videoContainers, function(videoContainer) {
                updateSize(videoContainer);
            });
        };
    };

    this.update = function() {
        videoContainers = document.querySelectorAll(selector);
        this.resize();
    };
};

export default videoResize;