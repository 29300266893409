import select from "dom-select";
import { classy as cl } from "./classy";
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.min'

const Spoilers = function(parent, openTime, wait) {
  openTime = openTime || 400
  parent = typeof parent === 'string' ? select(parent) : parent ? parent : document.body;

  var spoilerClicks = select.all('[data-spoiler]:not([data-spoiler-child])', parent);
  var spoilers = [];
  var activeId = null;

  var prevH = null;

  function getCoords(elem) {
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top  = box.top +  scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  }

  function smooth(position) {
    const scroll = new SmoothScroll();
    setTimeout(() => {
      scroll.animateScroll(position, false, {
        offset: 200,
        durationMin: 300
      });
    }, openTime)
  }

  function scrollInPlace(previous, current) {
    const offset = window.innerWidth < 768 ? 100 : 200;
    if (previous && current) {
      if (previous.y < current.y) {
        smooth((current.y - previous.h) - offset)
      } else {
        smooth(current.y - offset)
      }
    }
  }

  function open(s) {
    smooth(s.click)
    s.isOpen = true;
    s.content.style.cssText = 'height: '+height(s.content)+'px';
    setTimeout(function () {
      s.content.style.cssText = '';
    }, openTime)
    if (s.click) cl(s.click).addCl('active');
    if (s.content) cl(s.content).addCl('active');
  }

  function close(s) {
    if (s.isOpen) {
      s.isOpen = false;
      prevH = {
        h: height(s.content),
        y: getCoords(s.click).top
      }
      s.content.style.cssText = 'height: '+height(s.content)+'px';
      cl(s.click).rmCl('active');
      setTimeout(function () {
        cl(s.content).rmCl('active');
      }, 10)
      setTimeout(function () {
        s.content.style.cssText = '';
      }, wait ? openTime : 10)
    }
  }

  function click(ev, parentId) {
    var id = ev.target.dataset.spoiler ? ev.target.dataset.spoiler : false

    if (!id) {
      return false
    }

    var parentSpoiler = parentId ? spoilers.find(s => s.id === parentId) : null
    var sp = parentSpoiler ? parentSpoiler.children : spoilers
    var spoiler = sp.find(s => s.id === id)

    if (!spoiler) {
      return false
    }

    if (spoiler.isOpen) {
      close(spoiler);
      spoiler.children.forEach(close)
      activeId = null;
    } else {
      activeId = id;
      open(spoiler);

      spoilers.forEach(s => {
        s.children.forEach(s => {
          if (s.id !== id) close(s)
        })
        const childOpen = s.children && s.children.some(s => s.isOpen)
        if (!childOpen && s.id !== id) {
          close(s);
        }
      })
    }

    scrollInPlace(prevH, {
      h: height(spoiler.content),
      y: getCoords(spoiler.click).top
    })
  }

  function height(content) {
    return content && content.children[0] ? content.children[0].getBoundingClientRect().height : false
  }

  spoilerClicks.forEach(function (spoilerClick) {
    var id = spoilerClick.dataset.spoiler
    var content = select('#'+id, parent);
    var isOpen = spoilerClick.classList.contains('active');

    var children = select.all('[data-spoiler]', content).map(spoiler => {
      var parentId = spoilerClick.dataset.spoiler
      var id = spoiler.dataset.spoiler
      spoiler.addEventListener('click', function (ev) {
        click(ev, parentId)
      });
      return {
        id: id,
        click: spoiler,
        content: select('#'+id, content),
        isOpen: false,
        children: null
      }
    })

    var spoiler = {
      id: id,
      click: spoilerClick,
      content: content,
      isOpen: false,
      children: children
    }

    if (isOpen && content) {
      activeId = id;
      open(spoiler);
    }

    spoilers.push(spoiler);

    spoilerClick.addEventListener('click', click);
  });

  this.closeAll = function () {
    spoilers.forEach(close);
  }
}

export default Spoilers
