import "core-js/stable"
import 'regenerator-runtime/runtime'
import '../scss/style.scss'
//import '@types/googlemaps'

import barba from '@barba/core'
import select from 'dom-select'
import { classy as cl } from './helpers/classy'
import { scrollLock, scrollUnLock } from './helpers/scrollLock'
import throttle from 'lodash.throttle'
import SmoothScroll from 'smooth-scroll'
import LazyLoad from 'lazyload'
import Cookie from 'js-cookie'
import Stickyfill from 'stickyfilljs'
import axios from './helpers/axios'
import serialize from 'form-serialize'
import videoResize from './helpers/videoResize'
import Spoilers from './helpers/spoilers'
import scrollAnimatron from './helpers/scrollAnimatron'
import { Swiper, Navigation, Autoplay, EffectFade, Pagination, Controller } from 'swiper/js/swiper.esm.js'

//import { app as enquiryApp, formApp } from './shop'
import MicroModal from 'micromodal';

Swiper.use([Navigation, Autoplay, EffectFade, Pagination, Controller])

window.onload = function() {
  var $recaptcha = document.querySelector('#g-recaptcha-response');

  if($recaptcha) {
    $recaptcha.setAttribute("required", "required");
  }
};

(function (doc, win) {
  require('viewport-units-buggyfill').init();
  require('vanilla-autofill-event');

  window.openModal = false;
  window.dataLayer = window.dataLayer || [];
  const app = {};

  const nav = function (parent) {
    const burgers = select.all('.burger', parent);
    const header = select('.header', parent);
    const subMenus = select.all('.sub-menu', header);
    const nav = select('.nav', header);
    const scrollHeader = select('.scroll-header', parent)

    function resetRight() {
      nav.style.cssText = ''
      scrollHeader.style.cssText = ''
    }

    burgers.forEach(burger => {
      burger.addEventListener('click', function () {
        if (burger.classList.contains('open')) {
          window.openModal = false;
          scrollUnLock(() => { resetRight() })
        } else {
          window.openModal = header;
          scrollLock(nav, srollBarW => {
            nav.style.cssText = `right: ${srollBarW}px`
            scrollHeader.style.cssText = `right: ${srollBarW}px`
          });
        }
        cl(header).toCl('open');
        cl(burgers).toCls('open');
      })
    })

    select.all('.sub-trig', header).forEach((trig, i) => {
      trig.addEventListener('click', () => {
        cl(subMenus[i]).addCl('active')
        cl(nav).addCl('sub-open')
      })
    })

    select.all('.sub-back', header).forEach(trig => {
      trig.addEventListener('click', () => {
        cl(nav).rmCl('sub-open')
        cl(subMenus).rmCls('active')
      })
    })

    this.close = function () {
      cl(header).rmCl('open');
      cl(burgers).rmCls('open');
      window.openModal = false;
      scrollUnLock(() => { resetRight() })
      cl(subMenus).rmCls('active')
      cl(nav).rmCl('sub-open')
    }

    this.scroll = function () {
      if (window.pageYOffset > 160) cl(scrollHeader).addCl('show');
      else cl(scrollHeader).rmCl('show');
    }
  };

  function activeNav() {
    const firstSegment = location.pathname.split('/')[1];
    const lastSegment = location.pathname.split('/').pop();
    select.all('.header a, .nav a').forEach(function(nav) {
      const linkSegments = nav.pathname.split('/')
      const linkFirstSegment = linkSegments.length > 1 && linkSegments[1] !== '' ? linkSegments[1] : false
      const linkSecondSegment = linkSegments.length > 2 && linkSegments[2] !== '' ? linkSegments[2] : false

      if (linkSecondSegment && linkSecondSegment === lastSegment) cl(nav).addCl('active');
      else if (linkFirstSegment && !linkSecondSegment && linkFirstSegment === firstSegment) cl(nav).addCl('active');
      else cl(nav).rmCl('active');
    });
  }

  function smooth(el, offset, speed) {
    if (el) {
      const scroll = new SmoothScroll();
      scroll.animateScroll(el, false, {
        offset: offset || 200,
        updateURL: false,
        speed: speed || 600,
        durationMax: speed || 600
      });
    }
  }

  function setSmooth(parent) {
    select.all('.smooth-scroll', parent).forEach(anchor => {
      anchor.addEventListener('click', ev => {
        ev.preventDefault()
        ev.stopPropagation()
        const target = select(anchor.hash)
        if (target) smooth(target, 40, 1000)
      })
    })
  }

  function stickyEls(parent) {
    parent = parent || false;
    Stickyfill.removeAll();
    const stickys = select.all('.sticky', parent);
    if (stickys.length) Stickyfill.add(stickys);
  }

  app.swipers = [];
  function swipers(parent) {
    app.swipers.forEach(sw => sw.destroy());

    select.all('.fade-swiper', parent).forEach(fadeSwiper => {
      let speed = Number(fadeSwiper.dataset.speed) || false;
      let autoplay = Number(fadeSwiper.dataset.autoplay) || false;

      app.swipers.push(
        new Swiper(fadeSwiper, {
          effect: 'fade',
          speed: speed,
          loop: true,
          autoplay: autoplay ? {
            delay: autoplay,
          } : false,
          navigation: {
            nextEl: select('.control-right', fadeSwiper),
            prevEl: select('.control-left', fadeSwiper)
          },
          pagination: {
            el: select('.swiper-pagination', fadeSwiper),
            type: 'bullets',
          }
        })
      )
    })

    select.all('.slide-swiper', parent).forEach(sladeSwiper => {
      let speed = Number(sladeSwiper.dataset.speed) || 400;
      let autoplay = Number(sladeSwiper.dataset.autoplay) || false;

      app.swipers.push(
        new Swiper(sladeSwiper, {
          speed: speed,
          loop: !!autoplay,
          autoplay: autoplay ? {
            delay: autoplay,
          } : false,
          navigation: {
            nextEl: select('.control-right', sladeSwiper),
            prevEl: select('.control-left', sladeSwiper)
          },
          pagination: {
            el: select('.swiper-pagination', sladeSwiper),
            type: 'bullets',
          }
        })
      )
    })

    select.all('.thumbnail-slide-swiper', parent).forEach(sladeSwiper => {

      let speed = Number(sladeSwiper.dataset.speed) || 400;
      let autoplay = Number(sladeSwiper.dataset.autoplay) || false;

      const thumbsLoop = !sladeSwiper.classList.contains( 'no-thumb-loop' )

      const slider = new Swiper(sladeSwiper, {
        slidesPerView: 1,
        speed: speed,
        loop: thumbsLoop,
        loopedSlides: 2,
        centeredSlides: true,
        autoplay: autoplay ? {
          delay: autoplay,
        } : false,
        navigation: {
          nextEl: select('.control-right', sladeSwiper),
          prevEl: select('.control-left', sladeSwiper)
        }
      })
      
      const thumbs = new Swiper( sladeSwiper.nextElementSibling, {
        slidesPerView: 'auto',
        loop: thumbsLoop,
        loopedSlides: 2,
        spaceBetween: 15,
        centeredSlides: true,
        slideToClickedSlide: true
      })

      slider.controller.control = thumbs;
      thumbs.controller.control = slider;

      app.swipers.push( slider )
      app.swipers.push( thumbs )
    })

    setTimeout(function () {
      app.swipers.forEach(sw => sw.update());
    }, 200)
  }

  app.lazyload = null
  function setLazy(parent) {
    if (app.lazyload) app.lazyload.destroy();
    const images = select.all('.grid-image img', parent)
    app.lazyload = new LazyLoad(images)
  }

  function setMaps(parent) {
    function setMap(map) {
      const lat = typeof map.dataset.lat !== 'undefined' ? map.dataset.lat : false;
      const lng = typeof map.dataset.lon !== 'undefined' ? map.dataset.lon : false;
      const zoom = typeof map.dataset.zoom !== 'undefined' ? map.dataset.zoom : false;

      if (google && lat && lng) {
        let center;
        let pos = new google.maps.LatLng(lat, lng);
        let map = new google.maps.Map(map, {
          center: pos,
          zoom: Number(zoom),
          disableDefaultUI: true,
          draggable: true,
          zoomControl: true,
          scrollwheel: false,
          styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
        });

        // const markerSize = 12
        // const markerIcon = {
        //   path: `M${markerSize}, ${markerSize} m -${markerSize}, 0 a ${markerSize},${markerSize} 0 1,0 ${markerSize*2},0 a ${markerSize},${markerSize} 0 1,0 -${markerSize*2},0`,
        //   fillColor: '#EC2623',
        //   fillOpacity: 1,
        //   anchor: new google.maps.Point(markerSize, markerSize),
        //   strokeWeight: 0,
        //   scale: 1
        // };

        google.maps.event.addListener(map, 'idle', function() {
          center = map.getCenter();
        });

        google.maps.event.addDomListener(window, 'resize', function() {
          google.maps.event.trigger(map, 'resize');
          map.setCenter(center);
        });

        // const mapMarker = new google.maps.Marker({
        //   position: pos,
        //   map: map,
        //   draggable: false,
        //   icon: markerIcon,
        // })
      }
    }
    select.all('.gmap', parent).forEach(setMap)
  }

  function inputs(parent) {
    select.all('.input', parent).forEach(input => {
      if (input.value !== '') {
        input.classList.add('filled')
      }
      input.addEventListener('focus', function(el) {
        el.target.classList.add('filled')
      });
      input.addEventListener('focusout', function(el) {
        if (el.target.value === '') {
          el.target.classList.remove('filled')
        }
      });
    });
  }

  function forms(parent) {
    const search = select('.search-form', parent)

    if (search) {
      parent.addEventListener('submit', ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if (ev.target.action) {
          const query = serialize(ev.target);
          barba.go(ev.target.action+'?'+query)
        }
      })
    }

    select.all('.newsletter', parent).forEach(form => {
      const button = select('.button', form)

      let posting = false

      function reset() {
        posting = false
        cl(button).rmCl('is-loading')
        form.classList.add('is-posted')
        setTimeout(() => {
          form.reset()
          form.forEach(f => (cl(f).rmCl('filled')))
          form.classList.remove('is-posted')
        }, 3000)
      }

      form.addEventListener('submit', e => {
        e.preventDefault()
        e.stopPropagation()
        cl(button).addCl('is-loading')

        if (!posting) {
          posting = true
          axios.post(window.location.href, serialize(form)).then(({ data }) => {
            reset()
            const modal = doc.querySelector('#modal-1');
            if ( modal ) {
              const cookieDuration = Number.isInteger( parseInt( modal.dataset.cookieDuration ) ) ? parseInt( modal.dataset.cookieDuration ) : 30;
              Cookie.set( 'newsletter-popup', 'form-sent', { expires: cookieDuration } )
            }
            if (!data.success) {
              console.log(data)
            }
          }).catch(e => {
            reset()
            console.log(e)
          })
        }
      })
    })
  }

  function cookieBar(parent) {
    const coockieVal = Cookie.get('cookie-bar')
    const bar = select('.cookie-bar', parent)
    const bars = select.all('.snackbar', parent)
    const button = select('.button', bar)

    if (!coockieVal) {
      setTimeout(() => {
        cl(bar).addCl('open')
      }, 5000)
    }

    if (button) {
      button.addEventListener('click', () => {
        cl(bar).rmCl('open')
        Cookie.set('cookie-bar', 'closed', { expires: 30 })
      })
    }

    bars.forEach(bar => {
      const notificationVal = Cookie.get('notification-bar')
      const button = select('.close', bar)
      if (bar.classList.contains('cart-note')) {
        cl(bar).addCl('open')
      }
      else if (!notificationVal) {
        setTimeout(() => {
          cl(bar).addCl('open')
        }, 3000)
      }

      if (button) {
        button.addEventListener('click', () => {
          cl(bar).rmCl('open')
          if (!bar.classList.contains('cart-note')) {
            Cookie.set('notification-bar', 'closed', { expires: 2 })
          }
        })
      }
    })
  }

  function loadStorePoint(parent) {

    const storepoint = select('#storepoint-container', parent)

    if ( storepoint ) {
      let script = document.createElement('script');
      let storeId = storepoint.dataset.mapId;
      script.src = 'https://cdn.storepoint.co/api/v1/js/'+storeId+'.js';
      parent.appendChild(script);
    }
  }

  function renderCaptcha(parent) {
    select.all('.g-recaptcha', parent).forEach(capt => {

      if (grecaptcha) grecaptcha.render(capt, {
        sitekey: capt.dataset.sitekey
      })
    })
  }

  function videoInModal(parent) {
    const modalPlayerTriggers = parent.querySelectorAll( '.btn-modal-player' )
    modalPlayerTriggers.forEach( 
      modalPlayerTrigger => {
        const videoPlayerModal = parent.querySelector( `#${modalPlayerTrigger.dataset.modalId}` )
        parent.appendChild( videoPlayerModal )
        modalPlayerTrigger.addEventListener( 'click', e => {
          e.preventDefault()
          const modalVideo = videoPlayerModal.querySelector( '.modal-video' )
          const source = modalVideo.querySelector( 'source' )
          source.src = source.dataset.src
          MicroModal.init()
          MicroModal.show( modalPlayerTrigger.dataset.modalId, {
            onShow: modal => {
              modalVideo.play()
            },
            onClose: modal => {
              modalVideo.pause()
            }
          } )
        })
      }
    )
  }

  function outBarba() {

  }

  app.videRes = null
  function onBarba(parent) {
    activeNav()
    if (parent) {
      app.nav = new nav(parent)
      inputs(parent)
      forms(parent)
      cookieBar(parent)
      stickyEls(parent)
      swipers(parent)
      setLazy(parent)
      setMaps(parent)
      setSmooth(parent)
      app.spoilers = new Spoilers(parent)
      app.videRes = new videoResize('.has-cover-video')
      app.scroll = new scrollAnimatron([
        {
          el: '.an1',
          offset: 200,
          classAdd: 'fade-in-up-small'
        },
        {
          el: '.an2',
          offset: 200,
          classAdd: 'fade-in-up'
        },
        {
          el: '.an3',
          offset: 0,
          classAdd: 'fade-in-up-cover'
        },
        {
          el: '.an4',
          offset: 0,
          classAdd: 'fade-in'
        },
        {
          el: '.an-r',
          offset: 0,
          classAdd: 'fade-in-right'
        },
        {
          el: '.an-l',
          offset: 0,
          classAdd: 'fade-in-left'
        }
      ], parent);
      renderCaptcha(parent)
      videoInModal(parent)
    }
  }

  function newsletterPopup() {
    const modal = doc.querySelector('#modal-1');
    if ( modal ) {
      const delay = Number.isInteger( parseInt( modal.dataset.timeDelay ) ) ? parseInt( modal.dataset.timeDelay ) : 5000;
      const cookieDuration = Number.isInteger( parseInt( modal.dataset.cookieDuration ) ) ? parseInt( modal.dataset.cookieDuration ) : 30;
      MicroModal.init();
      if ( Cookie.get( 'newsletter-popup' ) == undefined ) {
        setTimeout(() => { MicroModal.show('modal-1', {
          onClose: modal => {
            Cookie.set( 'newsletter-popup', 'closed', { expires: cookieDuration } )
          }
        }) }, delay)
      }
    }
  }

  doc.addEventListener('DOMContentLoaded', function () {
    cl('.curtain').rmCl('open');
    outBarba();

    win.addEventListener('scroll', throttle(function () {
      if (app.nav) app.nav.scroll();
      if (app.scroll) app.scroll.scroll();
    }, 50));

    win.addEventListener('resize', throttle(function () {
      if (app.nav) app.nav.close();
      if (app.videRes) app.videRes.resize();
    }, 300));

    barba.init({
      timeout: 5000,
      prefetchIgnore: true,
      transitions: [
        {
          name: 'transition',
          leave() {
            const done = this.async();
            cl('.curtain').addCl('open');
            setTimeout(done, 500)
          }
        }
      ]
    })

    barba.hooks.leave(() => {
      if (app.nav) app.nav.close()
    })

    barba.hooks.enter(() => {
      window.scrollTo(0, 0);
      cl('.curtain').rmCl('open')
    })

    barba.hooks.afterEnter((data) => {
      onBarba(data.next.container)
      loadStorePoint(data.next.container)
      select.all('video:not(.modal-video)', data.next.container).forEach(v => { v.play() })
    })

    barba.hooks.after(() => {
      // window.dataLayer.push({
      //   "event": "pageview",
      //   "page": {
      //     "path": window.location.pathname,
      //     "title": document.title
      //   }
      // });
    })

    newsletterPopup()
  })
})(document, window);
